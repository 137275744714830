<template>
  <div class="programme py-5">
    <section class="speaker" id="speaker">
      <div class="heading text-light bg-primary py-3 text-center">
        <h1 class="gotham-font text-uppercase b-600 mb-0">SPEAKERS</h1>
      </div>
      <div class="sub-heading text-center text-primary py-3 bg-secondary">
        <h1 class="gotham-font text-uppercase b-600 mb-0">KEYNOTE SPEAKERS</h1>
      </div>
      <div class="key-speakers mb-3">
        <div class="container-fluid">
          <div class="row" v-for="(data, index) in keynote" :key="index">
            <div class="col-sm-12 py-5" :class="{ 'alt-row': index % 2 }">
              <div class="container">
                <div class="row">
                  <div class="col-sm-3 text-center mb-sm-0 mb-3">
                    <img :src="data.img" class="img-fluid" :alt="data.name"/>
                  </div>
                  <div class="col-sm-9 fs-4">
                    <p class="mb-2 gotham-font text-primary">{{ data.name }}</p>
                    <p v-html="data.desg" class="mb-0 opensans-font"></p>
                    <p class="mb-0 opensans-font">{{ data.location }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-heading text-center text-primary py-3 bg-secondary">
        <h1 class="gotham-font text-uppercase b-600 mb-0">INVITED SPEAKERS</h1>
      </div>
      <div class="invited-speakers mb-5">
        <div class="container-fluid">
          <div class="row" v-for="(data, index) in invited" :key="index">
            <div class="col-sm-12 py-5" :class="{ 'alt-row': index % 2 == 0 }">
              <div class="container">
                <div class="row">
                  <div class="col-sm-3 text-center mb-sm-0 mb-3">
                    <img :src="data.img" class="img-fluid" :alt="data.name"/>
                  </div>
                  <div class="col-sm-9 fs-4">
                    <p class="mb-2 gotham-font text-primary">{{ data.name }}</p>
                    <div v-html="data.desg" class="opensans-font"></div>
                    <!-- <p class="mb-0 opensans-font">{{ data.desg }}</p>
                    <p class="mb-0 opensans-font">{{ data.location }}</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="programme" id="programme">
      <div class="heading text-light bg-primary py-3 text-center">
        <h1 class="gotham-font text-uppercase b-600 mb-0">programme</h1>
      </div>
      <div class="container">
        <div class="row mt-5 mb-5">
          <div class="col-sm-10 mx-auto">
            <nav>
              <div
                  class="nav nav-tabs nav-fill"
                  :class="{
                  'rounded-pill': isUADesktop,
                  'nav-desktop-bg': isUADesktop,
                }"
                  id="nav-tab"
                  role="tablist"
              >

                <button
                    class="nav-link active  text-uppercase"
                    :class="{ 'rounded-pill': isUADesktop }"
                    id="nav-thurs-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-thurs"
                    type="button"
                    role="tab"
                    aria-controls="nav-thurs"
                    aria-selected="true"
                >
                  day 1
                  <br/>
                  26 september
                </button>
                <button
                    class="nav-link text-uppercase"
                    :class="{ 'rounded-pill': isUADesktop }"
                    id="nav-fri-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-fri"
                    type="button"
                    role="tab"
                    aria-controls="nav-fri"
                    aria-selected="true"
                >
                  day 2
                  <br/>
                  27 september
                </button>
                <button
                    class="nav-link text-uppercase"
                    :class="{ 'rounded-pill': isUADesktop }"
                    id="nav-sat-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-sat"
                    type="button"
                    role="tab"
                    aria-controls="nav-sat"
                    aria-selected="true"
                >
                  day 3
                  <br/>
                  28 september
                </button>
              </div>
            </nav>
            <div class="tab-content p-5" id="nav-tabContent">
              <div
                  class="tab-pane fade show active"
                  id="nav-thurs"
                  role="tabpanel"
                  aria-labelledby="nav-thurs-tab"
                  tabindex="0"
              >
                <img
                    src="@/assets/img/programme/tab1.png"
                    class="img-fluid"
                    alt=""
                />
              </div>
              <div
                  class="tab-pane fade"
                  id="nav-fri"
                  role="tabpanel"
                  aria-labelledby="nav-fri-tab"
                  tabindex="0"
              >
                <img
                    src="@/assets/img/programme/tab2.png"
                    class="img-fluid"
                    alt=""
                />
              </div>
              <div
                  class="tab-pane fade"
                  id="nav-sat"
                  role="tabpanel"
                  aria-labelledby="nav-sat-tab"
                  tabindex="0"
              >
                <img
                    src="@/assets/img/programme/tab3.png"
                    class="img-fluid"
                    alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="committee" id="committee">
      <div class="heading text-light bg-primary py-3 text-center">
        <h1 class="gotham-font text-uppercase b-600 mb-0">committee</h1>
      </div>
      <div class="sub-heading text-center text-primary py-3 bg-secondary">
        <h1 class="gotham-font text-uppercase b-600 mb-0">
          INTERNATIONAL SCIENTIFIC COMMITTEE
        </h1>
      </div>
      <div class="container">
        <div class="row py-5">
          <div class="col-sm-8 mx-auto">
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Peter Beckmerhagen, </span>
              Frohn GmbH
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. David Butler, </span>
              Strathclyde University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Chen Zhong, </span> Nanyang
              Technological University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. David Fan, </span> Nanyang
              Technological University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold"
              >Dr. Michael Fitzpatrick,
              </span>
              Coventry University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Domenico Furfari, </span>
              Airbus
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Supriyo Ganguly, </span>
              Cranfield University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Mario Guagliano, </span>
              Politecnico Milano
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold"
              >Dr. Ing. Thomas Haubold,
              </span>
              Rolls-Royce
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Hong Minghui, </span>
              Xiamen University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Sho ltoh, </span> Chiba
              University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Kang Chang Wei, </span>
              Institute of High Performance Computing, A*STAR
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold"
              >Dr. Alexander Korsunsky,
              </span>
              Oxford University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Kumar A Senthil, </span>
              National University of Singapore
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Hong Fei Liu, </span>
              Institute of Materials Research and Engineering
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Sergi Dosta Parras, </span>
              Universitat de Barcelona
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Claudia Palese, </span>
              University of the Witwatersrand
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Karsten Roetgger, </span>
              Ecoroll
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Yuji Sano, </span> Osaka
              University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Suman Shrestha, </span>
              Keronite, Curtiss-Wright
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Hitoshi Soyama, </span>
              Tohoku University
            </p>
            <p class="mb-2 opensans-font fs-5">
              <span class="text-primary fw-bold">Dr. Wang Hao Victor, </span>
              National University of Singapore
            </p>
          </div>
        </div>
      </div>
      <div class="sub-heading text-center text-primary py-3 bg-secondary">
        <h1 class="gotham-font text-uppercase b-600 mb-0">
          ORGANIZING COMMITTEE
        </h1>
      </div>
      <div class="container">
        <div class="row py-5">
          <div class="col-sm-10 mx-auto py-3 fs-5">
            <p class="mb-2 gotham-font text-primary fs-4">Organizing Chairs</p>
            <p class="mb-0 opensans-font">
              <span class="text-primary">Dr. Wei Yuefan, </span>
              Singapore Institute of Manufacturing Technology, Singapore
            </p>
            <p class="mb-0 opensans-font">
              Advanced Remanufacturing and Technology Centre, Singapore
            </p>
          </div>

          <div class="col-sm-10 mx-auto py-3 fs-5">
            <p class="mb-2 gotham-font text-primary fs-4">
              Secretary
            </p>
            <p class="mb-0 opensans-font">
              <span class="text-primary">Dr. He Wei, </span>
              Singapore Institute of Manufacturing Technology, Singapore
            </p>
          </div>

          <div class="col-sm-10 mx-auto py-3 fs-5">
            <p class="mb-2 gotham-font text-primary fs-4">Organising Committee</p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Mr Kunal Ahluwalia, </span>
              Advanced Remanufacturing and Technology Centre, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Ms. Raahgini Chandrasegaran, </span>Advanced
              Singapore Institute of Manufacturing Technology, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Mr. Ahmad Alif Bin Hamid, </span
              >Advanced Remanufacturing and Technology Centre, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Mr. Muhammad Azrul Shukri Azmi, </span>
              Advanced Remanufacturing and Technology Centre, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Dr. Henry Cheng, </span>
              Advanced Remanufacturing and Technology Centre, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Mr. Aldrich Chua, </span>
              Advanced Remanufacturing and Technology Centre, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Ms. Thivyaa Ramesh, </span>Advanced
              Remanufacturing and Technology Centre, A*STAR, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Mr. Nataniel Tham, </span>Advanced
              Remanufacturing and Technology Centre, A*STAR, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Dr. Michael Yap, </span
              >Coventry University Singapore Hub, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Mr. Chan Wai Luen, </span>
              Previously affiliated to Advanced Remanufacturing and Technology Centre, Singapore
            </p>
            <p class="mb-2 opensans-font">
              <span class="text-primary">Dr. Niroj Maharjan, </span>
              Previously affiliated to Advanced Remanufacturing and Technology Centre, Singapore
            </p>
          </div>

          <div class="col-sm-10 mx-auto py-3 fs-5">
            <p class="mb-0 gotham-font text-primary fs-4">Topical Chairs</p>


            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. Shashwat Shukla </span>
              Halliburton, Singapore
            </p>

            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. Henry Cheng </span>
              Advanced Remanufacturing and Technology Centre
            </p>

            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. Liu Hongfei </span>
              Institute of Materials Research and Engineering
            </p>

            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. Lin Ming </span>
              Institute of Materials Research and Engineering
            </p>


            <p class="opensans-font mb-2">
              <span class="text-primary">Mark Hyunpong Jhon </span>
              Institute of High Performance Computing
            </p>


            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. Ba Te  </span>
              Institute of High Performance Computing
            </p>

            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. Sabeur Msolli </span>
              Université de Technologie de Belfort-Montbéliard, France
            </p>


            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. Zhang Lei </span>
              (Institute of Materials Research and Engineering)
            </p>


            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. Hoon Sohn </span>
              Korea Advanced Institute of Science & Technology
            </p>

            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. David Fan </span>
              Nanyang Technological University, Singapore
            </p>

            <p class="opensans-font mb-2">
              <span class="text-primary">Dr. Shifeng Guo </span>
              Shenzhen Institutes of Advanced Technologies (SIAT), Chinese Academy of Sciences
            </p>

            <p class="mb-2 opensans-font">
              <span class="text-primary">Dr. Sankar Seetharaman </span>
              Advanced Remanufacturing and Technology Centre
            </p>

            <p class="mb-2 opensans-font">
              <span class="text-primary">Dr. Nam Vu </span>
              Halliburton
            </p>

            <p class="mb-2 opensans-font">
              <span class="text-primary">Dr. Mike Fitzpatrick </span>
              Coventry University
            </p>

          </div>
        </div>
      </div>
    </section>
    <contact/>
    <footerComp/>
  </div>
</template>

<script>
import footerComp from "@/components/layouts/footer.vue";
import contact from "@/components/layouts/contactus.vue";

export default {
  name: "programme",
  components: {
    footerComp,
    contact,
  },
  data() {
    return {
      keynote: [
        {
          id: 1,
          img: "/speakers/Keynote speakers/Christopher Berndt.jpg",
          name: "Prof. Christopher Berndt",
          desg: `
                  <p class="mb-0">Director, SEAM</p>
                  <p class="mb-0">Australian Research Council, Industrial Transformation Training Centre on “Surface Engineering for Advanced Materials”</p>
          `,
          location: "University Distinguished Professor, PhD, DEng",
        },
        {
          id: 1,
          img: "/speakers/Keynote speakers/1.png",
          name: "Dr. Ing. Thomas Haubold",
          desg: `
          <p class="mb-0">Engineering Associate Fellow, Special Processes Rolls-Royce</p>
          `,
          location: "Deutschland Ltd & Co KG",
        },
        {
          id: 2,
          img: "/speakers/Keynote speakers/2.png",
          name: "Dr. Christopher A. Schuh",
          desg: `
          <p class="mb-0">Professor of Metallurgy, Department of Materials Science and Engineering</p>
          `,
          location: "Massachusetts Institute of Technology",
        },
        {
          id: 3,
          img: "/speakers/Keynote speakers/3.png",
          name: "Dr. Arpana Verma",
          desg: `
          <p class="mb-0">Global Manager, Materials & Innovation</p>
          `,
          location: "Halliburton",
        },
      ],
      invited: [
        {
          id: 112,
          img: "/speakers/Invited speakers/Andrew-Ang.png",
          name: "Dr. Andrew Ang",
          desg: `
                  <p class="mb-0">Co-Director, Space Technology and Industry Institute (STII),</p>
                  <p class="mb-0">Director, Microscopy and Advanced Analytical Facility (MAAF)</p>
                  <p class="mb-0">Swinburne University of Technology, Australia</p>
                  `,
        },
        {
          id: 112,
          img: "/speakers/Invited speakers/david-butler.png",
          name: "Prof. David Butler",
          desg: `
                  <p class="mb-0">University of Strathclyde</p>
                `,
        },
        {
          id: 0,
          img: "/speakers/Invited speakers/1.png",
          name: "Dr. Yuttanant Boonyongmaneerat, Ph.D.",
          desg: `
                  <p class="mb-0">Deputy Director,</p>
                  <p class="mb-0">Metallurgy and Materials Science Research Institute,</p>
                  <p class="mb-0">Chulalongkorn University, Thailand</p>
                  <p class="mb-0">President of Thailand ElectroPlating Industrial Network Association (TEP NET)</p>
                  `,
        },
        {
          id: 1,
          img: "/speakers/Invited speakers/David.png",
          name: "Dr. David Fan",
          desg: `
                  <p class="mb-0">Associate Professor,</p>
                  <p class="mb-0">Nanyang Technological University, Singapore</p>
                  `,
        },
        {
          id: 2,
          img: "/speakers/Invited speakers/2.png",
          name: "Dr. Domenico Furfari",
          desg: `
                  <p class="mb-0">Airframe Research & Technology,</p>
                  <p class="mb-0">AIRBUS Operations GmbH, Germany</p>
                  `,
        },
        {
          id: 3,
          img: "/speakers/Invited speakers/3.png",
          name: "Dr. Guan Yingchun",
          desg: `
                  <p class="mb-0">Professor,</p>
                  <p class="mb-0">National Engineering Laboratory of Additive Manufacturing for Large Metallic Components</p>
                  <p class="mb-0">School of Mechanical Engineering and Automation International Research Institute for Multidisciplinary Science</p>
                  <p class="mb-0">Beihang University, China</p>
                  `,
        },
        {
          id: 31,
          img: "/speakers/Invited speakers/Guaglino.png",
          name: "Dr. Mario Guagliano",
          desg: `
                  <p class="mb-0">Professor,</p>
                  <p class="mb-0">Department of Mechanical Engineering,</p>
                  <p class="mb-0">Politecnico Milano, Italy</p>
                  `,
        },
        {
          id: 32,
          img: "/speakers/Invited speakers/Guo.png",
          name: "Dr. Guo Kesheng",
          desg: `
                  <p class="mb-0">Associate Researcher,</p>
                  <p class="mb-0">Jihua Laboratory, China</p>
                  `,
        },
        {
          id: 30,
          img: "/speakers/Invited speakers/John.jpg",
          name: "Dr. John Hock Lye Pang",
          desg: `
                  <p class="mb-0">Professor,</p>
                  <p class="mb-0">School of Mechanical and Aerospace Engineering,</p>
                  <p class="mb-0">Nanyang Technological University, Singapore</p>
                  `,
        },
        {
          id: 4,
          img: "/speakers/Invited speakers/hoon.png",
          name: "Dr. Hoon Sohn",
          desg: `
                  <p class="mb-0">Professor,</p>
                  <p class="mb-0">Civil and Environmental Engineering Department</p>

                  <p class="mb-0">Korea Advanced Institute of Science and Technology (KAIST)</p>
                  `,
        },
        {
          id: 5,
          img: "/speakers/Invited speakers/4.png",
          name: "Dr. Huang Yi Zhong",
          desg: `
                  <p class="mb-0">Associate Professor,</p>
                  <p class="mb-0">Nanyang Technological University, Singapore</p>
                  `,
        },

        {
          id: 6,
          img: "/speakers/Invited speakers/hu.png",
          name: "Dr. Hu Jiangfeng",
          desg: `
                  <p class="mb-0">Senior Scientist,</p>
                  <p class="mb-0">Surface Engineering Group (SEG), Advanced Remanufacturing & Technology Centre (ARTC)</p>
                  <p class="mb-0">Adjunct Assistant Professor</p>
                  <p class="mb-0">Department of Materials Science and Engineering (NUS)</p>
                  `,
        },
        {
          id: 6,
          img: "/speakers/Invited speakers/yuji.png",
          name: "Dr. Yuji Kobayashi",
          desg: `
                  <p class="mb-0">Sintokogio Ltd., Japan</p>
                  `,
        },
        {
          id: 61,
          img: "/speakers/Invited speakers/Yuka.png",
          name: "Dr. Yuka Iga",
          desg: `
                  <p class="mb-0">Professor,</p>
                  <p class="mb-0">Institute of Fluid Science,</p>
                  <p class="mb-0">Tohoku University, Japan</p>

                  `,
        },
        {
          id: 7,
          img: "/speakers/Invited speakers/LI.png",
          name: "Dr. Kun Li",
          desg: `
                  <p class="mb-0">Distinguished Professor,</p>
                  <p class="mb-0">Director High-performance Intelligent Additive Manufacturing (HπAM) Laboratory State</p>
                  <p class="mb-0">Key Laboratory of Mechanical Transmissions,</p>
                  <p class="mb-0">Chongqing University, China</p>

                  `,
        },
        {
          id: 71,
          img: "/speakers/Invited speakers/Liu.png",
          name: "Dr. Liu Hongfei",
          desg: `
                  <p class="mb-0">Senior Scientist,</p>
                  <p class="mb-0">Institute of Materials Research and Engineering</p>
                  `,
        },

        {
          id: 8,
          img: "/speakers/Invited speakers/5.png",
          name: "Dr. Yoshio Mizuta",
          desg: `
                  <p class="mb-0">Specially-appointed Assistant Professor,</p>
                  <p class="mb-0">Department of Quantum Beam Physics</p>
                  <p class="mb-0">SAN KEN, Osaka University, Japan</p>
                  `,
        },

        {
          id: 18,
          img: "/speakers/Invited speakers/nardi.png",
          name: "Mr. Aaron Nardi",
          desg: `
                  <p class="mb-0">Chief Technology Officer,</p>
                  <p class="mb-0">VRC Metal Systems, LLC, United States</p>
                  `,
        },
        {
          id: 9,
          img: "/speakers/Invited speakers/Ngo.png",
          name: "Dr. Andrew Ngo Chun Yong",
          desg: `
                  <p class="mb-0">Senior Scientist and Division Director,</p>
                  <p class="mb-0">Institute of Materials Research and Engineering, Singapore</p>
                  `,
        },
        {
          id: 10,
          img: "/speakers/Invited speakers/6.png",
          name: "Dr. Qu Xuanhui",
          desg: `
                  <p class="mb-0">Professor,</p>
                  <p class="mb-0">Institute of Powder Metallurgy</p>
                  <p class="mb-0">University of Science and Technology Beijing, China</p>
                  `,
        },
        {
          id: 11,
          img: "/speakers/Invited speakers/7.png",
          name: "Dr. Yuji Sano",
          desg: `
                  <p class="mb-0">Specially-appointed Professor,</p>
                  <p class="mb-0">Department of Quantum Beam Physics</p>
                  <p class="mb-0">SAN KEN, Osaka University, Japan</p>
                  `,
        },


        {
          id: 113,
          img: "/speakers/Invited speakers/Sha-Zhou.png",
          name: "Dr. Sha Zhou",
          desg: `
                  <p class="mb-0">Research Scientist,</p>
                  <p class="mb-0">Surface Engineering Group,</p>
                  <p class="mb-0">Advanced Remanufacturing and Technology Centre,</p>
                  <p>A*STAR Singapore</p>
                  `,
        },

        {
          id: 112,
          img: "/speakers/Invited speakers/Shi-Jianzhong.png",
          name: "Dr. Shi Jianzhong",
          desg: `
                  <p class="mb-0">Advanced Development Scientist,</p>
                  <p class="mb-0">Surface Engineering Group,</p>
                  <p class="mb-0">Advanced Remanufacturing and Technology Centre, A*STAR Singapore</p>
                  `,
        },
        {
          id: 12,
          img: "/speakers/Invited speakers/8.png",
          name: "Dr. Wang Hao",
          desg: `
                  <p class="mb-0">Assistant Professor,</p>
                  <p class="mb-0">Department of Mechanical Engineering</p>
                  <p class="mb-0">National University of Singapore, Singapore</p>
                  `,
        },

        {
          id: 133,
          img: "/speakers/Invited speakers/Zainul-Abidin.png",
          name: "Dr. Zainul Abidin",
          desg: `
                  <p class="mb-0">Group Leader,</p>
                  <p class="mb-0">Advanced in-situ Microscopy Group,</p>
                  <p class="mb-0">Institute of Materials Research and Engineering, A*STAR, Singapore</p>
                  `,
        },
        {
          id: 134,
          img: "/speakers/Invited speakers/Zhenhuai-Yang.png",
          name: "Dr. Zhenhuai Yang",
          desg: `
                  <p class="mb-0">Assistant Researcher,</p>
                  <p class="mb-0">Microwave and Vacuum Technology Laboratory,</p>
                  <p class="mb-0">Jihua Laboratory, Foshan, Guangdong, China</p>
                  `,
        },

        {
          id: 13,
          img: "/speakers/Invited speakers/9.png",
          name: "Dr. Zhang Baicheng",
          desg: `
                  <p class="mb-0">Associate Professor,</p>
                  <p class="mb-0">Institute of Powder Metallurgy</p>
                  <p class="mb-0">University of Science and Technology Beijing, China</p>
                  `,
        },
        {
          id: 14,
          img: "/speakers/Invited speakers/10.png",
          name: "Dr. Zhang Zheng",
          desg: `
                  <p class="mb-0">Head of the Structural Materials Department,</p>
                  <p class="mb-0">Institute of Materials Research and Engineering, Singapore</p>
                  `,
        },
        {
          id: 15,
          img: "/speakers/Invited speakers/11.png",
          name: "Dr. Zhang Zhiqian",
          desg: `
                  <p class="mb-0">Senior Scientist/ Group Manager,</p>
                  <p class="mb-0">Institute of High Performance Computing, A*STAR, Singapore</p>
                  `,
        },
      ],
    };
  },
  mounted() {
    this.globalDeviceCheck();
  },
};
</script>
<style lang="scss" scoped>
.programme {
  min-height: 100vh;

  .gotham-font {
    font-family: "GothamRounded", sans-serif;
  }

  .opensans-font {
    font-family: "Open Sans", sans-serif;
  }

  .alt-row {
    background: rgb(247, 247, 247);
  }

  .nav-desktop-bg {
    background: #5a89e3;
  }

  .nav-tabs {
    border-bottom: none;

    overflow: hidden;

    .nav-link {
      border: 1px solid #5a89e3;

      color: #fff;
      background-color: #5a89e3;
      font-weight: 700;
      padding: 0.5rem 2.8rem;

      &.active {
        border: none;
        color: #f5e868;
        background: #1f3485;
      }
    }
  }

  // .tab-content {
  //   background: rgb(211, 151, 250);
  //   background: -moz-linear-gradient(
  //     146deg,
  //     rgba(211, 151, 250, 1) 0%,
  //     rgba(131, 100, 232, 1) 100%
  //   );
  //   background: -webkit-linear-gradient(
  //     146deg,
  //     rgba(211, 151, 250, 1) 0%,
  //     rgba(131, 100, 232, 1) 100%
  //   );
  //   background: linear-gradient(
  //     146deg,
  //     rgba(211, 151, 250, 1) 0%,
  //     rgba(131, 100, 232, 1) 100%
  //   );
  //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d397fa",endColorstr="#8364e8",GradientType=1);
  //   .table {
  //     color: #fff;
  //   }
  // }
}
</style>
